import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Grid, Column } from '@twilio-paste/grid';
import { Text } from '@twilio-paste/text';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { SiteLink } from '../../components/SiteLink.tsx';
import { ComponentOverviewTable } from '../../components/component-overview-table';
import { Breadcrumb, BreadcrumbItem } from '../../components/breadcrumb';
import { SidebarCategoryRoutes } from '../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../components/callout';
import { StatusLegend } from '../../components/StatusLegend';
export const pageQuery = graphql`
  {
    allPasteComponent {
      edges {
        node {
          name
          version
          status
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <content>
      <Breadcrumb mdxType="Breadcrumb">
  <BreadcrumbItem to="/" mdxType="BreadcrumbItem">Home</BreadcrumbItem>
      </Breadcrumb>
      <Heading as="h1" variant="heading10" mdxType="Heading">
  Components
      </Heading>
    </content>
    <hr></hr>
    <contentwrapper>
      <content>
        <StatusLegend mdxType="StatusLegend" />
        <ComponentOverviewTable categoryRoute={SidebarCategoryRoutes.COMPONENTS} componentsList={props.data.allPasteComponent.edges} mdxType="ComponentOverviewTable" />
        <Callout mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Don't see a component, primitive, or layout you need listed here?</CalloutTitle>
  <UnorderedList mdxType="UnorderedList">
    <ListItem mdxType="ListItem">
      First off, we recommend <SiteLink to="/roadmap" mdxType="SiteLink">checking our roadmap</SiteLink>.
    </ListItem>
    <ListItem mdxType="ListItem">
      Secondly, we recommend{' '}
      <Anchor href="https://github.com/twilio-labs/paste/issues" mdxType="Anchor">creating an issue on GitHub</Anchor> with your
      component, primitive, or proposal.
    </ListItem>
    <ListItem mdxType="ListItem">
      While not all requests are accepted for the design system, we may be able to suggest suitable alternatives - for
      this, drop us a note on #help-design-system or join our Weekly Office hours.
    </ListItem>
    <ListItem mdxType="ListItem">
      We've also written a guide for{' '}
      <Anchor href="/tokens/how-to-compose-custom-ui-with-tokens" mdxType="Anchor">
        how you can build custom components with Tokens
      </Anchor>{' '}
      and still benefit from Paste design updates
    </ListItem>
  </UnorderedList>
        </Callout>
      </content>
    </contentwrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      